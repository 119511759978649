import brMessages from '../locales/pt_BR.json';
import {ptBR} from '@material-ui/core/locale';

const brLang = {
  messages: {
    ...brMessages,
  },
  muiLocale: ptBR,
  locale: 'pt-BR',
};
export default brLang;
