import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import {onCognitoUserSignOut, onJWTAuthSignout, onSignOutAuth0User, onSignOutFirebaseUser, toggleNavCollapsed} from '../../../../redux/actions';
import {useDispatch} from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import useStyles from './AppHeader.style';
import AppLogo from '../../../../shared/components/AppLogo';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuthUser } from '@crema/utility/AppHooks';
import { AuthType } from 'shared/constants/AppEnums';
import { useHistory } from 'react-router';
interface AppHeaderProps {}
const AppHeader: React.FC<AppHeaderProps> = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useAuthUser();
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>      
      <MenuItem>Minha Conta</MenuItem>
            <MenuItem onClick={() => history.push('/customersGhost/list')}>Meus Cadastros</MenuItem>
            <MenuItem
            onClick={() => {
              if (user && user.authType === AuthType.AWS_COGNITO) {
                dispatch(onCognitoUserSignOut());
              } else if (user && user.authType === AuthType.FIREBASE) {
                dispatch(onSignOutFirebaseUser());
              } else if (user && user.authType === AuthType.AUTH0) {
                dispatch(onSignOutAuth0User());
              } else if (user && user.authType === AuthType.JWT_AUTH) {
                dispatch(onJWTAuthSignout());
              }
            }}>
            Logout
          </MenuItem>
    </Menu>
  );
  
  return (
    <>
      <AppBar className='app-bar' color='inherit'>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(toggleNavCollapsed())}>
            
            </IconButton>
          </Hidden>

          <AppLogo />
          <Box className={classes.grow} />
          <Box className={classes.sectionDesktop}>
            <MenuItem onClick={() => history.push('/customersGhost/list')}>Meus Cadastros</MenuItem>
            <MenuItem onClick={() => history.push('/user/myAccount/')}>Minha Conta</MenuItem>

            <MenuItem
            onClick={() => {
              if (user && user.authType === AuthType.AWS_COGNITO) {
                dispatch(onCognitoUserSignOut());
              } else if (user && user.authType === AuthType.FIREBASE) {
                dispatch(onSignOutFirebaseUser());
              } else if (user && user.authType === AuthType.AUTH0) {
                dispatch(onSignOutAuth0User());
              } else if (user && user.authType === AuthType.JWT_AUTH) {
                dispatch(onJWTAuthSignout());
              }
            }}>
            Logout
          </MenuItem>
          </Box>
          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'>
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};
export default AppHeader;
