import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./Signin')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup',
        component: React.lazy(() => import('./Signup')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/confirm-signup',
        component: React.lazy(() => import('./ConfirmSignupAwsCognito')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/landing-page',
        component: React.lazy(() => import('./LandingPage')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/events',
        component: React.lazy(() => import('../events')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/terms',
        component: React.lazy(() => import('../terms')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/reset-password',
        component: React.lazy(() => import('./ResetPasswordAwsCognito')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/customersDetails',
        component: React.lazy(() => import('../CustomersDetails')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/eventsDetails',
        component: React.lazy(() => import('../eventsDetails')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
 
];
