export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'admin',
        title: 'Admin',
        messageId: 'sidebar.app.admin',
        type: 'collapse',
        icon: 'dashboard',
        children: [
          {
            id: 'NeighborhoodId',
            title: 'Neighborhoods',
            messageId: 'sidebar.app.Neighborhoods',
            type:'collapse',
            children: [
             {
               id: 'NeighborhoodsList',
               title: 'Listar',
               messageId: 'sidebar.app.list',
               icon:'listAlt',
               type: 'item',
               url: '/admin/Neighborhoods/list',
             },
             {
               id: 'NeighborhoodId',
               title: 'Adicionar',
               icon: 'add',
               messageId: 'sidebar.app.add',
               type: 'item',
               url: '/admin/Neighborhoods/add',
             },
            ]
          },
          {
            id: 'PaymentMethodId',
            title: 'PaymentMethods',
            messageId: 'sidebar.app.PaymentMethods',
            type:'collapse',
            children: [
             {
               id: 'PaymentMethodsList',
               title: 'Listar',
               messageId: 'sidebar.app.list',
               icon:'listAlt',
               type: 'item',
               url: '/admin/PaymentMethods/list',
             },
             {
               id: 'PaymentMethodId',
               title: 'Adicionar',
               icon: 'add',
               messageId: 'sidebar.app.add',
               type: 'item',
               url: '/admin/PaymentMethods/add',
             },
            ]
          },
          {
            id: 'customersId',
            title: 'customers',
            messageId: 'sidebar.app.subscribers',
            type:'collapse',
            children: [
             {
               id: 'customersList',
               title: 'Listar',
               messageId: 'sidebar.app.list',
               icon:'listAlt',
               type: 'item',
               url: '/customersGhost/list',
             },
             {
               id: 'customersId',
               title: 'Adicionar',
               icon: 'add',
               messageId: 'sidebar.app.add',
               type: 'item',
               url: '/customersGhost/add',
             },
            ]
          },
          {
            id: 'customersId',
            title: 'customers',
            messageId: 'sidebar.app.subscribers',
            type:'collapse',
            children: [
          
             {
               id: 'customersId',
               title: 'Adicionar',
               icon: 'add',
               messageId: 'sidebar.app.add',
               type: 'item',
               url: '/customers/add',
             },
            ]
          },
          {
            id: 'bannerId',
            title: 'banner',
            messageId: 'sidebar.app.banner',
            type:'collapse',
            children: [
  
             {
              id: 'bannerList',
              title: 'Listar',
              messageId: 'sidebar.app.list',
              icon:'listAlt',
              type: 'item',
              url:'/admin/banner/list',
            },
            {
              id: 'bannerId',
              title: 'Adicionar',
              icon: 'add',
              messageId: 'sidebar.app.add',
              type: 'item',
              url: '/admin/banner/add',
            },
            ]
          },
          {
            id: 'categoryId',
            title: 'category',
            messageId: 'sidebar.app.category',
            type:'collapse',
            children: [
             {
               id: 'categoryList',
               title: 'Listar',
               messageId: 'sidebar.app.list',
               icon:'listAlt',
               type: 'item',
               url: '/admin/category/list',
             },
             {
               id: 'categoryId',
               title: 'Adicionar',
               icon: 'add',
               messageId: 'sidebar.app.add',
               type: 'item',
               url: '/admin/category/add',
             }
           
            ]
          },
         
       
        //  {
        //    id: 'client',
        //    title: 'Clients',
        //    messageId: 'sidebar.app.clients',
        //    type:'collapse',
        //    children: [
        //     {
        //       id: 'clientsList',
        //       title: 'Listar',
        //       messageId: 'sidebar.app.list',
        //       icon:'listAlt',
        //       type: 'item',
        //       url: '/admin/clients/list',
        //     },
        //     {
        //       id: 'clientsAdd',
        //       title: 'Adicionar',
        //       icon: 'add',
        //       messageId: 'sidebar.app.add',
        //       type: 'item',
        //       url: '/admin/clients/add/',
        //     },
        //    ]
        //  },
         {
           id: 'coupons',
           title: 'coupons',
           messageId: 'sidebar.app.coupons',
           type:'collapse',
           children: [
            {
              id: 'couponsList',
              title: 'Listar',
              messageId: 'sidebar.app.list',
              icon:'listAlt',
              type: 'item',
              url: '/admin/coupons/list',
            },
            {
              id: 'couponsAdd',
              title: 'Adicionar',
              icon: 'add',
              messageId: 'sidebar.app.add',
              type: 'item',
              url: '/admin/coupons/add/',
            },
           ]
         },
        //  {
        //    id: 'address',
        //    title: 'Address',
        //    messageId: 'sidebar.app.address',
        //    type:'collapse',
        //    children: [
        //     {
        //       id: 'addressList',
        //       title: 'Listar',
        //       messageId: 'sidebar.app.list',
        //       icon:'listAlt',
        //       type: 'item',
        //       url: '/admin/address/list',
        //     },
        //     {
        //       id: 'user',
        //       title: 'CRM',
        //       icon: 'add',
        //       messageId: 'sidebar.app.dashboard.details',
        //       type: 'item',
        //       url: '/dashjls',
        //     },
        //    ]
        //  },
        
      
        
        
         {
           id: 'eventsId',
           title: 'events',
           messageId: 'sidebar.app.events',
           type:'collapse',
           children: [
            {
              id: 'eventsList',
              title: 'Listar',
              messageId: 'sidebar.app.list',
              icon:'listAlt',
              type: 'item',
              url: '/admin/events/list',
            },
            {
              id: 'eventsId',
              title: 'Adicionar',
              icon: 'add',
              messageId: 'sidebar.app.add',
              type: 'item',
              url: '/admin/events/add',
            },
           ]
         },
         {
          id: 'plans',
          title: 'Plans',
          messageId: 'sidebar.app.plans',
          type:'collapse',
          children: [
            {
              id: 'plansList',
              title: 'Listar',
              messageId: 'sidebar.app.list',
              icon:'listAlt',
              type: 'item',
              url: '/admin/SalePlans/list',
            },
           {
             id: 'crm',
             title: 'CRM',
             messageId: 'sidebar.app.add',
             icon:'add',
             type: 'item',
             url: '/admin/SalePlans/add',
           },
          ]
        },

        //  {
        //   id: 'user',
        //   title: 'User',
        //   icon:'group',
        //   messageId: 'sidebar.app.user',
        //   type:'collapse',
        //   children: [
        //    {
        //      id: 'crm',
        //      title: 'CRM',
        //      messageId: 'sidebar.app.dashboard.list',
        //      icon:'listAlt',
        //      type: 'item',
        //      url: '/admin/users',
        //    },
        //    {
        //      id: 'user',
        //      title: 'CRM',
        //      icon: 'add',
        //      messageId: 'sidebar.app.dashboard.details',
        //      type: 'item',
        //      url: '/dashboards/details',
        //    },
        //   ]
        // },
        
//          {
//            id: 'addressTypes',
//            title: 'AddressTypes',
//            messageId: 'sidebar.app.addressTypes',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },
//          {
//            id: 'Address',
//            title: 'Address',
//            messageId: 'sidebar.app.agents',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },
//          {
//            id: 'client',
//            title: 'Client',
//            messageId: 'sidebar.app.breeds',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },
//          {
//            id: 'client',
//            title: 'Client',
//            messageId: 'sidebar.app.cids',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },
//          {
//            id: 'client',
//            title: 'Client',
//            messageId: 'sidebar.app.cities',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },
//          {
//            id: 'client',
//            title: 'Client',
//            messageId: 'sidebar.app.clientsContacts',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },
//          {
//            id: 'client',
//            title: 'Client',
//            messageId: 'sidebar.app.clientsNewsletter',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },
//          {
//            id: 'client',
//            title: 'Client',
//            messageId: 'sidebar.app.clientsOperators',
//            type:'collapse',
//            children: [
//             {
//               id: 'crm',
//               title: 'CRM',
//               messageId: 'sidebar.app.dashboard.list',
//               icon:'listAlt',
//               type: 'item',
//               url: '/dah',
//             },
//             {
//               id: 'user',
//               title: 'CRM',
//               icon: 'add',
//               messageId: 'sidebar.app.dashboard.details',
//               type: 'item',
//               url: '/dashjls',
//             },
//            ]
//          },  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.clientsSystems',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         }, {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.companyOperators',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.costCenter',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },





//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.countries',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },









//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.department',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },









//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.doctors',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },




//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.educations',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },







//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.family',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },



//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.functions',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },




//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.groupUsers',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },










//  {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.groupUsersMenu',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },

//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.higher',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.hospitals',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.kinship',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },        {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.languages',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },


//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.maritalStatus',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },

//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.menusType',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.offices',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },



 
      









 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.procedures',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },











 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.providers',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },

        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.responsabilities',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         }, 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.shifts',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.specialities',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },


        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.states',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },

 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.status',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         }, 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.statusSolicitation',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.storedEventMap',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.systems',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },

 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.systemsLanguages',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.systemMenus',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.transportsLines',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.transports',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },

        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.treatments',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.typeProviders',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.typeSolicitation',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.typeTransports',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         }, 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.unions',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.userAccess',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         }, 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.usersPermissions',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },        
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.usersPermissionsMenus',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         }, 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.userProperties',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },
 
//         {
//           id: 'client',
//           title: 'Client',
//           messageId: 'sidebar.app.userStatus',
//           type:'collapse',
//           children: [
//            {
//              id: 'crm',
//              title: 'CRM',
//              messageId: 'sidebar.app.dashboard.list',
//              icon:'listAlt',
//              type: 'item',
//              url: '/dah',
//            },
//            {
//              id: 'user',
//              title: 'CRM',
//              icon: 'add',
//              messageId: 'sidebar.app.dashboard.details',
//              type: 'item',
//              url: '/dashjls',
//            },
//           ]
//         },        
        // {
        //   id: 'client',
        //   title: 'Client',
        //   messageId: 'sidebar.app.userTokens',
        //   type:'collapse',
        //   children: [
        //    {
        //      id: 'crm',
        //      title: 'CRM',
        //      messageId: 'sidebar.app.dashboard.list',
        //      icon:'listAlt',
        //      type: 'item',
        //      url: '/dah',
        //    },
        //    {
        //      id: 'user',
        //      title: 'CRM',
        //      icon: 'add',
        //      messageId: 'sidebar.app.dashboard.details',
        //      type: 'item',
        //      url: '/dashjls',
        //    },
        //   ]
        // },










 
        // {
        //   id: 'client',
        //   title: 'Client',
        //   messageId: 'sidebar.app.userTypes',
        //   type:'collapse',
        //   children: [
        //    {
        //      id: 'crm',
        //      title: 'CRM',
        //      messageId: 'sidebar.app.dashboard.list',
        //      icon:'listAlt',
        //      type: 'item',
        //      url: '/dah',
        //    },
        //    {
        //      id: 'user',
        //      title: 'CRM',
        //      icon: 'add',
        //      messageId: 'sidebar.app.dashboard.details',
        //      type: 'item',
        //      url: '/dashjls',
        //    },
        //   ]
        // },
        
         
         

        ],
      },
    ],
  },
];
export default routesConfig;
