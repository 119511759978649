import React, {useContext} from 'react';
import AppContext from '../../../utility/AppContext';
import {ThemeStyle} from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';


interface AppSidebarProps {
  variant?: string;
  position?: 'left' | 'bottom' | 'right' | 'top';
}

const AppSidebar: React.FC<AppSidebarProps> = ({

}) => {
  const {themeStyle} = useContext<AppContextPropsType>(AppContext);




 

  if (themeStyle === ThemeStyle.STANDARD) {
 
  }

  return (
    <>
   
    </>
  );
};

export default AppSidebar;
