import React, {useContext} from 'react';
// import Avatar from '@material-ui/core/Avatar';
import {useDispatch} from 'react-redux';
import {
  onCognitoUserSignOut,
  onJWTAuthSignout,
  onSignOutAuth0User,
  onSignOutFirebaseUser,
} from '../../../redux/actions';
import {useAuthUser} from '../../../@crema/utility/AppHooks';
import AppContext from '../../../@crema/utility/AppContext';
import {makeStyles} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import Box from '@material-ui/core/Box';
import {AuthType, Fonts, ThemeMode} from '../../constants/AppEnums';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {AuthUser} from '../../../types/models/AuthUser';

const useStyles = makeStyles(theme => {
  return {
    crUserInfo: {

      minHeight: 0,
     
    },
    profilePic: {
      width: '60px',
      height: '60px',
      fontSize: 24,
      backgroundColor: '#7b1641',
    },
    userInfo: {
      width: 'calc(100% - 25px)',
    },
    userName: {
   
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      fontWeight: Fonts.MEDIUM,
      color: (props: {themeMode: ThemeMode}) =>
        props.themeMode === ThemeMode.LIGHT ? '#313541' : '#7b1641',
    },
    designation: {
      marginTop: -2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: 'pointer',
    },
  };
});

const UserInfo: React.FC<{}> = () => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const dispatch = useDispatch();
  const user: AuthUser | null = useAuthUser();

  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getUserAvatar = () => {
  //   if (user && user.displayName) {
  //     return user.displayName.charAt(0).toUpperCase();
  //   }
  //   if (user && user.email) {
  //     return user.email.charAt(0).toUpperCase();
  //   }
  // };

  const classes = useStyles({themeMode});

  return (
    <Box>
      {/* <Box display='flex' alignItems='center' justifyContent='center'>
        {user && user.photoURL ? (
          <Avatar className={classes.profilePic} src={user.photoURL} />
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
        )}
   
      </Box> */}
      <Box mx="auto">
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'>
            {/* <Box mb={0} className={clsx(classes.userName)}>
              {user && (user.displayName ? user.displayName : 'Admin User ')}
            </Box> */}
            <Box
              ml={3}
              className={classes.pointer}
              color={themeMode === 'light' ? '#313541' : 'white'}>
              {/* <ExpandMoreIcon onClick={handleClick} /> */}
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem>My account</MenuItem>
                <MenuItem
                  onClick={() => {
                    if (user && user.authType === AuthType.AWS_COGNITO) {
                      dispatch(onCognitoUserSignOut());
                    } else if (user && user.authType === AuthType.FIREBASE) {
                      dispatch(onSignOutFirebaseUser());
                    } else if (user && user.authType === AuthType.AUTH0) {
                      dispatch(onSignOutAuth0User());
                    } else if (user && user.authType === AuthType.JWT_AUTH) {
                      dispatch(onJWTAuthSignout());
                    }
                  }}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>  
            {/* <Box 
              className={classes.designation} 
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
                System Manager
            </Box> */}
        </Box>
    </Box>
  );
};

export default UserInfo;
