import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const adminConfigs = [  
  // {
  //   auth: authRole.user,
  //   routes: [
  //     {
  //       path: '/admin/clients/add/',
  //       component: React.lazy(() => import('./clients/Add')),
  //     },
  //   ],
  // },
  // {
  //   auth: authRole.user,
  //   routes: [
  //     {
  //       path: '/admin/clients/list',
  //       component: React.lazy(() => import('./clients/List')),
  //     },
  //   ],
  // },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/users',
        component: React.lazy(() => import('./users')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/PaymentMethods/add',
        component: React.lazy(() => import('./PaymentMethods')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/PaymentMethods/list',
        component: React.lazy(() => import('./PaymentMethods/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/Neighborhoods/add',
        component: React.lazy(() => import('./Neighborhoods')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/Neighborhoods/list',
        component: React.lazy(() => import('./Neighborhoods/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/customers/add',
        component: React.lazy(() => import('./customers')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/customersGhost/list',
        component: React.lazy(() => import('./customersGhost/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/customersGhost/add',
        component: React.lazy(() => import('./customersGhost')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/user/myAccount',
        component: React.lazy(() => import('./myAccount')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/category/list',
        component: React.lazy(() => import('./categories/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/category/add',
        component: React.lazy(() => import('./categories')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/SalePlans/list',
        component: React.lazy(() => import('./salePlans/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/SalePlans/add',
        component: React.lazy(() => import('./salePlans')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/banner/add',
        component: React.lazy(() => import('./banner')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/banner/list',
        component: React.lazy(() => import('./banner/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/events/list',
        component: React.lazy(() => import('./events/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/events/add',
        component: React.lazy(() => import('./events')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/coupons/list',
        component: React.lazy(() => import('./coupons/List')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin/coupons/add',
        component: React.lazy(() => import('./coupons')),
      },
    ],
  },

];
