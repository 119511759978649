import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser} from '../../shared/constants/AppConst';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';

import * as CryptoJS from 'crypto-js';

// Declare this key and iv values in declaration
const key = CryptoJS.enc.Utf8.parse('4512631236589784');
const iv = CryptoJS.enc.Utf8.parse('4512631236589784');

// Methods for the encrypt and decrypt Using AES
const encryptUsingAES256= (val:string) => {
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(val), key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    //console.log('Encrypted :' + encrypted);
    //decryptUsingAES256(encrypted);
    return encrypted;
}

// const decryptUsingAES256 = (decString: any) => {
//     var decrypted = CryptoJS.AES.decrypt(decString, key, {
//         keySize: 128 / 8,
//         iv: iv,
//         mode: CryptoJS.mode.CBC,
//         padding: CryptoJS.pad.Pkcs7
//     });
//     //console.log('Decrypted : ' + decrypted);
//     //console.log('utf8 = ' + decrypted.toString(CryptoJS.enc.Utf8));
// return decrypted;
// }
export const onJwtUserSignUp = (body: {
  Email: string;
  Password: string;
  Name: string;
  IsActive: boolean;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    body.Password = encryptUsingAES256(body.Password).toString();
    dispatch(fetchStart());
    try {
      await jwtAxios.post('userssite/register', body).then((res) => {
        if(res.status === 200) {
          jwtAxios.post('/email/sendMail', {To: body.Email, Subject: "Cadastro realizado com sucesso!!"});
        }
      })
      dispatch(fetchSuccess());
      dispatch(
        showMessage('Sucess.')
      )
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};


export const onJwtSignIn = (body: {Login: string; Password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
   // console.log(body.Password);
    body.Password = encryptUsingAES256(body.Password).toString();
    //console.log(body.Password); 
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('login/loginSite', body);
      
      if(res.data.Token !== null) {
        localStorage.setItem('token', res.data.Token);
        dispatch(setJWTToken(res.data.Token));
        await loadJWTUser(dispatch);
      }
      else {
        dispatch(fetchError("Usuário Inválido"));
      }

    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const loadJWTUser = async (dispatch: Dispatch<AppActions>) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.get('login/GetDataLoginsite'); 
    console.log(res.data.UsersSite)
    dispatch(fetchSuccess());
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(res.data),
    });
  } catch (err) {
    console.log('error!!!!', err.response.error);
    dispatch(fetchError(err.response.error));
  }
};

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (authUser: any): AuthUser => {
   
  return {
    authType: AuthType.JWT_AUTH,
    UserSiteId: authUser.UsersSite.UserSiteId,
    displayName: authUser.UsersSite.Name,
    email: authUser.UsersSite.Email,
    role: defaultUser.role,
    token: authUser._id,
    uid: authUser._id,
    //photoURL: authUser.avatar,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
    }, 500);
  };
};
