import React, {useContext, useEffect} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../../@crema/utility/AppContext';
import {ThemeMode} from '../../constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import AppContextPropsType from '../../../types/AppContextPropsType';
import { useHistory } from 'react-router-dom';
//import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';

// export const AppLogoUrl :string = {


// };


const AppLogo = () => {
  const history = useHistory();
  //const [logo, setLogo] = useState("");
  useEffect(() => {
    //pega a url atual;
    //const url = 'http://www.admin.ttoperadora.com.br';

    //pega a ultima palavra após a ultima barra ( / );
    //const regex =  url.match('\.([^.\.]*\.)');

    // jwtAxios.post('/test',regex).then((res) => {
    //   const data = res.data;
    //   setLogo(data);
    // })

  },[])

  //console.log(logo);

  //vai ser o state
  const logoImg = "/assets/images/logo-with-name.png"

  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 48,
      marginRight: 10,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot} onClick={()=> history.push('/')}>
      <Hidden smUp>
        <img 
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? '/assets/images/logo-with-name.png'
              : '/assets/images/logo-with-name.png'
          }
          alt='Uzenix Logo'
        />
      </Hidden>
      <Hidden xsDown>
        <img
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? `${logoImg}`
              : `${logoImg}`
          }
          alt='Uzenix Logo'
        />
      </Hidden>
    </Box>
  );
};

export default AppLogo;
